










































import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { mapWeekday, Weekday } from "@/helpers/availabilityUtils";
import { userModule } from "@/store/modules/user";
import Availability, { Times } from "@/interfaces/Availability";
import { useNotifier } from "@/providers/notifier";
import handleError from "@/helpers/errors";

export default defineComponent({
  name: "SellerAvailabilityTimePicker",
  setup() {
    const notify = useNotifier();

    const times = [
      "15:00 - 16:00",
      "16:00 - 17:00",
      "17:00 - 18:00",
      "18:00 - 19:00",
      "19:00 - 20:00",
      "20:00 - 21:00",
    ] as Times[];

    const days: Weekday[] = [
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
    ];

    const availability = ref<Availability>({
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    });

    onMounted(() => {
      if (userModule.state.profile.availability) {
        availability.value = { ...userModule.state.profile.availability };
      }
    });

    async function save(): Promise<void> {
      try {
        await userModule.actions.updateUserProfile({
          availability: availability.value,
          uid: userModule.state.userId,
        });

        notify({
          title: "Oppdatert 😁",
          message: "",
          type: "success",
        });
      }
      catch (e) {
        handleError(e);
        notify({
          title: "Oooooops! 🙁",
          message: "Klarte ikke oppdatere tilgjengelighet.",
          type: "error",
        });
      }
    }

    return {
      availability,
      days,
      mapWeekday,
      times,
      save,
    };
  },
});
