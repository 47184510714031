<template>
  <v-container>
    <v-row class="mb-2">
      <v-col class="text-center">
        <h3 class="news">
          <strong style="color: orange">Nyhet!</strong><br>
        </h3>
        <h1 class="mb-2">
          Bli matchet
        </h1>
        <h3 class="mb-2">
          Vi matcher deg med elever uten at du trenger å gjøre noe!
        </h3>
      </v-col>
    </v-row>

    <v-alert
      type="success"
      style="max-width: 600px; margin: auto"
      class="mb-8"
      v-if="available"
      shaped
    >
      <v-row align="center">
        <v-col>
          <strong>Du er klar for å bli matchet! 🥳</strong><br>
          Du får SMS når vi har funnet elev til deg.
        </v-col>
      </v-row>
    </v-alert>

    <v-expansion-panels focusable>
      <v-expansion-panel data-test-id="new-students-capacity">
        <v-expansion-panel-header>
          <h3>
            <v-icon aria-label="false">
              mdi-account-multiple
            </v-icon>
            1. Hvor mange nye elever ønsker du deg?
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-alert
            type="info"
            class="mt-2"
            shaped
            text
          >
            <strong>Velg hvor mange nye elever du vil ha.</strong><br>
            Antallet reduseres automatisk hver gang du får en ny elev.<br>
            Du får ikke nye elever så lenge antallet er 0.
          </v-alert>
          <new-student-capacity />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel data-test-id="weekdays">
        <v-expansion-panel-header>
          <h3>
            <v-icon aria-label="false">
              mdi-calendar
            </v-icon>
            2. Når er du ledig for nye elever?
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <seller-availability-time-picker />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel data-test-id="categories">
        <v-expansion-panel-header>
          <h3>
            <v-icon aria-label="false">
              mdi-school
            </v-icon>
            3. Hvilke fag ønsker du elever i?
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <category-picker />
        </v-expansion-panel-content>
      </v-expansion-panel>

      <v-expansion-panel data-test-id="about">
        <v-expansion-panel-header>
          <h3>
            <v-icon aria-label="false">
              mdi-account
            </v-icon>
            4. Litt om deg som personlig lærer
          </h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <about-seller />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
import SellerAvailabilityTimePicker from "@/components/availability/SellerAvailabilityTimePicker.vue";
import CategoryPicker from "@/components/shared/CategoryPicker.vue";
import AboutSeller from "@/components/AboutSeller.vue";
import { computed, defineComponent } from "@vue/composition-api";
import { userModule } from "@/store/modules/user";
import NewStudentCapacity from "@/components/availability/NewStudentCapacity.vue";
import { isAvailable } from "@/helpers/availabilityUtils";

export default defineComponent({
  name: "AvailableForNewProjects",
  components: {
    NewStudentCapacity,
    AboutSeller,
    CategoryPicker,
    SellerAvailabilityTimePicker,
  },
  setup() {
    const available = computed(() => {
      const availability = userModule.state.profile.availability;
      let times = [];
      for (const day in availability) times.push(availability[day].length);
      times = times.filter(time => time);
      return isAvailable(userModule.state.profile) &&
        times.length &&
        userModule.state.profile.categories.length &&
        userModule.state.user.gender &&
        userModule.state.profile.newStudentCapacity > 0;
    });

    return {
      available,
    };
  },
});
</script>

<style lang="scss" scoped>
p {
  margin-top: 0px;
  margin: 20px;
}
</style>
