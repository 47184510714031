



































































import { mapGender } from "@/helpers/genderUtils";
import Profile from "@/interfaces/Profile";
import User from "@/interfaces/User";
import { useNotifier } from "@/providers/notifier";
import { userModule } from "@/store/modules/user";
import { defineComponent, ref, reactive } from "@vue/composition-api";

export default defineComponent({
  name: "AboutSeller",
  setup() {
    const notify = useNotifier();
    const loading = ref(false);

    const p = userModule.state.profile;
    const profile = reactive<Partial<Profile>>({
      aboutText: p.aboutText,
      interestsText: p.interestsText,
      tutorText: p.tutorText,
      sellerNotes: p.sellerNotes,
      uid: userModule.state.userId,
    });

    const u = userModule.state.user;
    const user = reactive<Partial<User>>({
      gender: u.gender,
      uid: userModule.state.userId,
    });

    const formRules = {
      required: (v: string) => !!v || "Obligatorisk felt",
    };

    const genders = ref([
      {
        text: mapGender("male"),
        value: "male",
      },
      {
        text: mapGender("female"),
        value: "female",
      },
      {
        text: mapGender("other"),
        value: "other",
      },
    ]);

    async function save() {
      loading.value = true;
      try {
        await Promise.all([
          userModule.actions.updateUserProfile({
            aboutText: profile.aboutText,
            interestsText: profile.interestsText,
            tutorText: profile.tutorText,
            sellerNotes: profile.sellerNotes,
            uid: userModule.state.userId,
          }),
          userModule.actions.updateUserObject({
            gender: user.gender,
            uid: userModule.state.userId,
          }),
        ]);
        notify({
          title: "Lagret 🥳",
          message: "",
          type: "success",
        });
      }
      catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        notify({
          title: "Oooooops! 🙁",
          message: "Klarte ikke oppdatere.",
          type: "error",
        });
      }
      loading.value = false;
    }

    return {
      formRules,
      genders,
      loading,
      profile,
      save,
      user,
    };
  },
});
