































import { computed, defineComponent } from "@vue/composition-api";
import { userModule } from "@/store/modules/user";
import { useNotifier } from "@/providers/notifier";

export default defineComponent({
  name: "NewStudentCapacity",
  setup() {
    const notify = useNotifier();
    const capacityChoices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
    let newStudentCapacity = userModule.state.profile.newStudentCapacity | 0;
    const changeCurrentCapacity = (value: number) => {
      newStudentCapacity = value;
    };
    const currentCapacity = computed(() => userModule.state.profile.newStudentCapacity | 0);

    async function save(): Promise<void> {
      try {
        await userModule.actions.updateUserProfile({
          newStudentCapacity: newStudentCapacity,
          uid: userModule.state.userId,
        });

        notify({
          title: "Oppdatert 😁",
          message: "",
          type: "success",
        });
      }
      catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        notify({
          title: "Oooooops! 🙁",
          message: "Klarte ikke oppdatere antall ønskede elever.",
          type: "error",
        });
      }
    }

    return {
      currentCapacity,
      changeCurrentCapacity,
      capacityChoices,
      save,
      newStudentCapacity,
    };
  },
});
