


























import { defineComponent, onMounted, ref } from "@vue/composition-api";
import { activeCategories } from "@/helpers/categories";
import { userModule } from "@/store/modules/user";
import { useNotifier } from "@/providers/notifier";

export default defineComponent({
  name: "CategoryPicker",
  setup: () => {
    const notify = useNotifier();
    const loading = ref(false);
    const selection = ref<string[]>([]);

    onMounted(() => {
      selection.value = userModule.state.profile.categories;
    });

    async function save() {
      loading.value = true;

      try {
        await userModule.actions.updateUserProfile({
          categories: selection.value,
          uid: userModule.state.userId,
        });
        notify({
          title: "Lagret 🥳",
          message: "",
          type: "success",
        });
      }
      catch (e) {
        // TODO: Error handler
      }
      loading.value = false;
    }

    return {
      activeCategories,
      loading,
      save,
      selection,
    };
  },
});
